import React from 'react';
import ReactDOM from 'react-dom/client';
import styled from "styled-components";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Navigation from './components/navigation_bar/Navigation';
import { BrowserRouter as Router} from 'react-router-dom';
import Content from './components/Content';
import Footer from './components/footer/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

root.render(
  <React.StrictMode>
    <Router>
      <Container>
        <Navigation />
        <Content />
        <Footer />
      </Container>
      
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
