import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../definitions/colors";

const NavOption = (props) => {


    const Linkacz = styled(NavLink)`
        color: ${COLORS.blackText};
        opacity: 100%;
        transition: 0.5s;

        &:hover{
            opacity: 60%;
            transform: scale(110%);
        }

        @media (max-width: 758px) {
            visibility: hidden;
        }
    `

    const Icon = styled.img`
        position: relative;
        float: left;
        height: 2.5rem;

        @media (max-width: 758px) {
            visibility: hidden;
        }
    `

    const Text = styled.p`
        position: relative;
        float: left;
        line-height: 2.5rem;
        text-align: center;
        margin: 0 0.5rem 0 0.5rem;
        font-size: 1.3rem;

        @media (max-width: 758px) {
            visibility: hidden;
        }
    `

    return (
        <Linkacz to={props.dest} style={({isActive}) => ({
            
        })}>
            <Icon src={props.icon}></Icon>
            <Text>{props.text}</Text>
        </Linkacz>   
    )

}

export default NavOption
