import React from "react";
import { COLORS } from "../../definitions/colors";
import styled from "styled-components";
import Logo from "./Logo";
import NavOption from "./NavOption";

const Header = styled.header`
  /* position: relative; */
  /* float: left; */
  position: fixed;
  z-index: 999;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${COLORS.headerBackground};
  `

  const Line = styled.hr`
  position: relative;
  float: left;
  background-color: ${COLORS.accent};
  padding: 0;
  margin: 0;
  width: 100%;
  border: 0.1rem solid ${COLORS.accent};
`;

  const NavHolder = styled.div`
    display: flex;
    position: relative;
    width: 80%;
    margin: auto;
    padding: 5px 0 5px 0;
    align-items: center;
    justify-content: space-evenly;
  `

const Navigation = () => {
    return (
      <Header>
        <Line />
        <NavHolder>
          <NavOption icon="/icons/breads.png" text="Chleby" dest="/chleby"></NavOption>
          <NavOption icon="/icons/bread.png" text="Bułki" dest="/bulki"></NavOption>
          <Logo />
          <NavOption icon="/icons/cinnamon-roll.png" text="Słodkości" dest="/slodkosci"></NavOption>
          <NavOption icon="/icons/mason-jar.png" text="Inne" dest="/inne"></NavOption>
        </NavHolder>
        <Line />
      </Header>
    );
  }

export default Navigation;
