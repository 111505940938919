import React from "react"
import styled from "styled-components"
import { COLORS } from "../../definitions/colors"

const Footer = () => {

    const Background = styled.footer`
        position: relative;
        float: left;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${COLORS.accent};
        color: ${COLORS.whiteText};
    `

    const ContactInformation = styled.div`
        text-align: center;
    `

    return (
        <Background>
            <ContactInformation>
                <h2>Kontakt</h2>
                <p>Telefon kontaktowy: +0047 96724953 <br /> Zamówienia proszę składać w wiadomościach prywatnych</p>
            </ContactInformation>
        </Background>
    )
}

export default Footer
