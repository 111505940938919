import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../definitions/colors";

const MenuOption = ({image, alt, name, dest}) => {

    const Holder = styled.div`
        aspect-ratio : 1 / 1;
        width: 40%;

        @media (max-width: 758px) {
            width: 80%;
        }
    ` 

    const Background = styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        aspect-ratio : 1 / 1;
        background-color: ${COLORS.accent};
        margin: 0.5rem;
        padding: 0.3rem;
    `

    const Image = styled.img`
        aspect-ratio: 1/1;
        width: 100%;
        object-fit: cover;
    `

    const Button = styled(NavLink)`
        position: absolute;
        background-color: ${COLORS.accent};
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        opacity: 0%;
        width: 100%;
        height: 100%;
        top: 0;
        transition: 0.3s;
        font-size: 2.6em;
        color: ${COLORS.whiteText};
        text-decoration: none;

        &:hover {
            opacity: 90%;
        }

        @media (max-width: 768px){
            opacity: 100%;
            background-color: transparent;
        }
    `

    const HeaderText = styled.h1`
        font-size: 1.3em;
        text-align: center;
        padding: 0px;
        margin: 0px;

        @media (max-width: 768px){
            font-size: 1.3em;
            text-shadow: -2px -2px 0 ${COLORS.accent}, 2px -2px 0 ${COLORS.accent}, -2px 2px 0 ${COLORS.accent}, 2px 2px 0 ${COLORS.accent};
        }
    `

    const ShowOffer = styled.p`
        font-size: 0.5em;

        @media (max-width: 768px){
            background-color: ${COLORS.accent};
            padding: 7px;
            border-radius: 10px;
        }
    `

    return (
        <Holder>
            <Background>
                <Image src={image} alt={alt}></Image>
                <Button to={dest}>
                    <HeaderText>{name}</HeaderText>
                    <ShowOffer>Zobacz ofertę</ShowOffer>
                </Button>
            </Background>
        </Holder>
    )
}

export default MenuOption
