export const PRODUCTS = {
    currency: "kr",
    breads: {
      section_name: "Chleby",
      section_description: "",
      products: [
        {
          name: "Nowe! Chleb pszenno-żytni EKO",
          description: "Wyjątkowy chleb, pieczony z ekologicznej mąki ze świeżo mielonych ziaren, łączący tradycję i zdrowie w każdym kęsie. Pieczony na zakwasie.",
          cost: 80,
          per: "szt",
          img_path: "/gallery/breads/chleb_eko.jpg"
        },
        {
          name: "Chleb tostowy mleczny",
          description: "Delikatny i puszysty chleb tostowy, wzbogacony o mleko, idealny na chrupiące tosty.",
          cost: 75,
          per: "550g",
          img_path: "/gallery/breads/tostowy.jpg"
        },
        {
          name: "Chleb razowy pszenno-żytni",
          description: "Pełnoziarnisty chleb łączący pszenicę i żyto, o bogatym smaku i wysokiej zawartości błonnika. Pieczony na zakwasie.",
          cost: 60,
          per: "900g",
          img_path: "/gallery/breads/chleb_razowy_pz.jpg"
        },
        {
          name: "Chleb żytni",
          description: "Tradycyjny chleb na bazie mąki żytniej, o charakterystycznym, lekko kwaskowatym smaku. Pieczony na zakwasie.",
          cost: 55,
          per: "szt",
          img_path: "/gallery/breads/chleb_zytni.jpg"
        },
        {
          name: "Chleb pszenno-żytni",
          description: "Zbalansowana mieszanka mąki pszennej i żytniej, która nadaje pieczywu idealną wilgotność i strukturę. Pieczony na zakwasie.",
          cost: 55,
          per: "szt",
          img_path: "/gallery/breads/chleb_pszenny.jpg"
        },
        {
          name: "Chleb z płatkami owsianymi",
          description: "Pożywny chleb wzbogacony o pełnowartościowe płatki owsiane, dla zdrowia i smaku. Pieczony na zakwasie.",
          cost: 50,
          per: "szt",
          img_path: "/gallery/breads/chleb_pszenny2_cleanup.jpg"
        },
		    {
          name: "Chleb wiejski",
          description: "Rustykalny chleb o chrupiącej skórce i miękkim wnętrzu, wypiekany według własnej receptury. Pieczony na zakwasie.",
          cost: 50,
          per: "szt",
          img_path: "/gallery/breads/chleb_wiejski.jpg"
        }
      ]
    },
    rolls: {
      section_name: "Bułki",
      section_description: "",
      products: [
        {
          name: "Bułka hamburgerowa",
          description: "Miękka i puszysta bułka idealna do przygotowania domowych hamburgerów.",
          cost: 16,
          per: "szt",
          img_path: "/gallery/rolls/hamburgerowa.jpg"
        },
        {
          name: "Chałka Challah",
          description: "Tradycyjna żydowska chałka o delikatnym smaku i pięknie splecionej formie.",
          cost: 75,
          per: "420g",
          img_path: "/gallery/rolls/chalka_challah.jpg"
        },
        {
          name: "Chałka drożdżowa na kefirze",
          description: "Aromatyczna chałka na bazie kefiru, wyjątkowo puszysta i delikatna.",
          cost: 75,
          per: "500g",
          img_path: "/gallery/rolls/chalka_kefir.jpg"
        },
        {
          name: "Chałka drożdzowa",
          description: "Słodka, drożdżowa chałka z kruszonką, doskonała do herbaty lub kawy.",
          cost: 70,
          per: "500g",
          img_path: "/gallery/rolls/chalka.jpg"
        },
        {
          name: "Bułka pszenna",
          description: "Klasyczna bułeczka, idealna na śniadanie lub kanapki.",
          cost: 10,
          per: "szt",
          img_path: "/gallery/rolls/bulki_pszenne.jpg"
        },
        {
          name: "Bułka razowa",
          description: "Pożywna bułka z mąki razowej o bogatym, pełnoziarnistym smaku.",
          cost: 10,
          per: "szt",
          img_path: "/gallery/rolls/bulki_razowe.jpg"
        },
        {
          name: "Bagietka zwykła",
          description: "Klasyczna bagietka o miękkim wnętrzu, idealna do kanapek lub jako dodatek do dań.",
          cost: 15,
          per: "szt",
          img_path: "/gallery/rolls/bagietka.jpg"
        },
        // {
        //   name: "Bagietka czosnkowa",
        //   description: "",
        //   cost: 16,
        //   per: "szt",
        //   img_path: "/gallery/rolls/bagietka.jpg"
        // },
      ]
    },
    cookies: {
      section_name: "Ciastka",
      section_description: "",
      products: [
        {
          name: "Rurki z kremem",
          description: "Kruche rurki wypełnione lekkim, słodkim kremem waniliowym, idealne na deser.",
          cost: 175,
          per: "kg",
          img_path: "/gallery/sweet/rurki.jpg"
        },
        {
          name: "Drożdżówki (różne smaki)",
          description: "Puszyste i aromatyczne drożdżówki, dostępne w różnych smakach, idealne na słodką przekąskę.",
          cost: 15,
          per: "szt",
          img_path: "/gallery/sweet/drozdzowki.jpg"
        },
        {
          name: "Ciastka owsiane",
          description: "Zdrowe i chrupiące ciastka owsiane, pełne błonnika i smaku.",
          cost: 16,
          per: "100g",
          img_path: "/gallery/sweet/ciastka_owsiane.jpg"
        }
      ]
    },
    other: {
      section_name: "Inne produkty",
      setion_description: "",
      products: [
        {
          name: "Bułka tarta",
          description: "Drobno mielona bułka tarta, idealna do panierowania i wypieków.",
          cost: 50,
          per: "kg",
          img_path: "/gallery/other/bulka_tarta.jpg"
        },
        // {
        //   name: "Zakwas na barszcz biały",
        //   description: "",
        //   cost: 60,
        //   per: "1l",
        //   img_path: "/gallery/other/zakwas_barszcz.jpg"
        // }
      ]
    }
  }