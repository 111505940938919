import React from "react";
import styled from "styled-components";
import { Route, Routes } from 'react-router-dom';
import Home from "./sites/Home";
import { PRODUCTS } from "../definitions/products";
import Products from "./sites/Products";

const Content = () => {

    const NavPlaceHolder = styled.div`
        position: relative;
        float: left;
        width: 100%;
        height: 3.5rem;
    `

    const Section = styled.section`
        position: relative;
        float: left;
        width: 100%;
        flex: 1;
    `

    const Offer = styled.div`
        position: relative;
        width: 60%;
        margin: 3rem auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (max-width: 768px){
            width: 100%;
        }
    `

    return (
        <>
        <NavPlaceHolder />
        <Section>
            <Offer>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/chleby" element={<Products product={PRODUCTS.breads}/>} />
                    <Route path="/bulki" element={<Products product={PRODUCTS.rolls}/>} />
                    <Route path="/slodkosci" element={<Products product={PRODUCTS.cookies}/>} />
                    <Route path="/inne" element={<Products product={PRODUCTS.other}/>} />
                </Routes>
            </Offer>
        </Section>
        </>
    )
}

export default Content
