import React from "react"
import ProductOption from "../ProductOption"

const Products = (props) => {
    return (
            <>
            {props.product.products.map((product) => {
                return (
                    <ProductOption product={product} />
                )
            })}
            </>
    )
}

export default Products
