import React from "react";
import styled from "styled-components";
import { COLORS } from "../definitions/colors";

const ProductOption = (props) => {

    const Holder = styled.div`
        width: 40%;

        @media (max-width: 758px) {
            width: 80%;
        }
    `

    const Background = styled.div`
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        /* aspect-ratio : 1 / 1; */
        background-color: ${COLORS.accent};
        margin: 0.5rem;
        padding: 0.3rem;

        min-height: 500px;

        @media (max-width: 758px){
            min-height: 100px;
        }
    `

    const FirstSection = styled.div`

    `

    const Image = styled.img`
        aspect-ratio: 1.5 / 1;
        width: 100%;
        object-fit: cover;
    `

    const Info = styled.div`
        /* background-color: ${COLORS.accent}; */
        display: flex;
        justify-content: start;
        flex-direction: column;
        /* align-items: center; */
        /* opacity: 70%; */
        /* width: 100%; */
        /* height: 100%; */
        top: 0;
        padding: 10px;
        transition: 0.3s;
        color: ${COLORS.whiteText};
        text-decoration: none;
    `

    const ProductName = styled.h1`
        font-size: 1.5em;
        /* text-align: center; */
        position: relative;
        margin: 5px 0px 0px 0px;
        /* top: 0; */
        /* padding: 0px 10px 0px 10px; */
        /* color: ${COLORS.accent}; */
        font-weight: bold;
        /* text-shadow: -1px -1px 0 ${COLORS.accent}, 1px -1px 0 ${COLORS.accent}, -1px 1px 0 ${COLORS.accent}, 1px 1px 0 ${COLORS.accent}; */
        /* text-shadow: -1px -1px 0 ${COLORS.whiteText}, 1px -1px 0 ${COLORS.whiteText}, -1px 1px 0 ${COLORS.whiteText}, 1px 1px 0 ${COLORS.whiteText}; */
    `

    const MoreInfo = styled.div`
        /* background-color: pink; */
        /* position: absolute; */
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: right;
        font-weight: bolder;
        color: ${COLORS.whiteText};
        text-decoration: none;
        /* text-shadow: -1px -1px 0 ${COLORS.accent}, 1px -1px 0 ${COLORS.accent}, -1px 1px 0 ${COLORS.accent}, 1px 1px 0 ${COLORS.accent}; */
    `

    const Description = styled.p`
        font-size: 1em;
        /* text-align: center; */
        /* text-shadow: -1px -1px 0 ${COLORS.accent}, 1px -1px 0 ${COLORS.accent}, -1px 1px 0 ${COLORS.accent}, 1px 1px 0 ${COLORS.accent}; */
    `

    const Cost = styled.p`
        text-align: right;
        font-size: 1.5em;
        margin: 0px;

    `

    return (
        <Holder>
            <Background>
                <FirstSection>
                    <Image src={props.product.img_path} alt={props.product.name}></Image>
                    <Info>
                        <ProductName>{props.product.name}</ProductName>
                        <Description>{props.product.description}</Description>
                    </Info>
                </FirstSection>
                <MoreInfo>
                    <Cost>{props.product.cost}kr/{props.product.per}</Cost>
                </MoreInfo>
            </Background>
        </Holder>
    )
}

export default ProductOption
