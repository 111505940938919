import React from "react"
import MenuOption from "../MenuOption"

const Home = () => {
    return (
            <>
            <MenuOption image="/gallery/main/chleb_zytni.jpg" name="Chleby" dest="/chleby"></MenuOption>
            <MenuOption image="/gallery/main/bulki_pszenne.jpg" name="Bułki" dest="/bulki"></MenuOption>
            <MenuOption image="/gallery/main/drozdzowki.jpg" name="Słodkości" dest="/slodkosci"></MenuOption>
            <MenuOption image="/gallery/main/bulka_tarta.jpg" name="Inne produkty" dest="/inne"></MenuOption>
            </>
    )
}

export default Home
