import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Image = styled.img`
        height: 3.5em;
    `

const Logo = () => {
    
    return (    
        <NavLink to="/">
            <Image src="/logo.png" alt="logo.png"></Image>
        </NavLink>
        
    )
}

export default Logo
